// ant-form style

.ant-form {
    //     height: calc(100vh - (rem(54) + rem(72) + rem(32) + rem(33))) !important;
    margin-bottom: rem(24);

    @media (max-width: 576.98px) {
        .ant-row {
            row-gap: 0 !important;
        }
    }
}

.ant-form,
.ant-form>.form-body {
    display: flex;
    flex-direction: column;
    // height: 100%;
}

.ant-picker .ant-picker-input>input {
    font-family: $font-primary;
    color: $dark-gray;
}

.ant-input,
.ant-select-single .ant-select-selector,
textarea.ant-input,
.ant-input-number,
.ant-picker,
.PhoneInput {
    border-radius: rem(4);
    color: $dark-gray;
}

.ant-input,
.ant-select-selector,
.ant-select-selection-search-input,
.ant-picker,
.PhoneInput,
.ant-select-single {
    min-height: rem(48);
}

.ant-input-number,
.ant-input-number-input,
.PhoneInputInput {
    min-height: rem(46);
}

.ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: rem(48) !important;
}

.ant-select-multiple .ant-select-selection-item {
    min-height: rem(28);
}

.ant-select-multiple .ant-select-selection-item-content,
.ant-select-selection-item-remove {
    line-height: rem(24) !important;
}

.ant-select-selection-overflow-item .ant-select-selection-search-input {
    min-height: rem(24);
}

.ant-form-item .ant-form-item-label>label::after {
    content: none;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-inline-start: rem(4);
    color: $color-danger;
    font-size: rem(14);
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.ant-form-vertical {
    .ant-form-item-label {
        padding-bottom: rem(4);

        label {
            font-size: rem(14);
            color: $medium-gray;

            &:after {
                content: none;
                visibility: visible;
            }
        }
    }
}

.PhoneInput {

    .PhoneInputCountry {
        border-right: 1px solid $border-color;
        padding-right: rem(11);
        padding-left: rem(11);
    }

    .PhoneInputInput {
        padding: 0 rem(11) 0 rem(6);
        border: none;
        background-color: transparent;
        font-size: 1rem;
        color: $dark-gray;

        &:hover,
        &:focus-visible {
            outline: none;
        }
    }

    .PhoneInputCountrySelect:focus+.PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
        color: $dark-gray;
    }

    .PhoneInputCountrySelect:focus+.PhoneInputCountryIcon+.PhoneInputCountrySelectArrow {
        color: $theme-gray;
    }
}

.ant-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number,
.ant-picker,
.ant-input-number-group-wrapper .ant-select-selector,
.PhoneInput {
    border-width: rem(1);
    border-style: solid;
    border-color: $border-color;
    background-color: $light-blue-hover;

    &:hover,
    &:focus,
    &.ant-input-focused {
        // border-color: $border-color;
        border-color: transparent;
        box-shadow: 0 0 0 2px rgba(217, 217, 217, 0.6);
    }

    &-disabled,
    &[disabled],
    &-disabled:hover,
    &[disabled]:hover {
        border-color: $border-color !important;
        background-color: $border-color;
    }
}

.ant-input:hover,
.ant-input:focus,
.ant-input.ant-input-focused,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-picker:hover,
.ant-picker-focused {
    // border-color: $border-color;
    border-color: transparent !important;
    box-shadow: 0 0 0 2px rgba(217, 217, 217, 0.6);
}

.ant-input-number,
.ant-picker,
.ant-input-number-group-wrapper,
.PhoneInput {
    width: 100%;
}

.ant-input-status-error,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: $border-color;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $border-color !important;
    background-color: $border-color !important;
    box-shadow: none !important;
}

.ant-form-item .ant-form-item-explain-error {
    font-size: rem(14);
    color: $color-error;
}

.ant-input-number-group-wrapper {
    .ant-input-number {
        width: 70%;
    }

    .ant-select-selection-item {
        text-align: left;
        padding-left: rem(11);
        width: calc(100% - rem(11));
    }

    .ant-select-selection-search {
        inset-inline-start: 0;
        inset-inline-end: 0;
    }

    .ant-select-selection-search-input,
    .ant-select-selection-item {
        color: $color-black !important;
    }

    .ant-input-number-group-addon {
        // background-color: $light-blue-hover;
        background-color: $border-color;
        color: $dark-gray;
        padding: 0;

        .ant-form-item {
            margin-bottom: 0;
        }

        .ant-select-selector {
            padding: 0;
        }

        .ant-form-item-explain-error {
            position: absolute;
            bottom: rem(-25);
        }

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
            padding-left: rem(11);
        }
    }
}

.form-actions {
    display: flex;
    // margin-top: auto;

    @media only screen and (min-width: 576px) {
        justify-content: flex-end;
    }

    @media only screen and (max-width: 575.98px) {
        margin-top: rem(24);
    }

    .ant-col {
        @media (min-width: 480px) and (max-width: 575.98px) {
            width: 50%;
        }

        @media only screen and (max-width: 479.98px) {
            min-height: rem(48);
            height: auto;
            white-space: normal;
            width: 100%;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }

        button {
            @media only screen and (max-width: 575.98px) {
                width: 100%;
            }

            // @media (min-width: 480px) and (max-width: 575.98px) {
            //     width: 50%;
            // }

            @media only screen and (max-width: 479.98px) {
                padding-right: rem(6) !important;
                padding-left: rem(6) !important;
            }
        }
    }
}

.ant-form .ant-row.form-actions {
    row-gap: rem(16) !important;
}