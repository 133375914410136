// ant-aside style

.sidebar-nav {
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.15);

    .ant-menu {
        padding-bottom: rem(55);
    }
}

aside .ant-menu .ant-menu-item {
    border-radius: 0;
    margin-inline: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: 100%;
    height: rem(55);
    // line-height: rem(55);
    padding: 0;

    &:hover,
    &.ant-menu-item-selected {
        background: $light-blue !important;
        color: $dusk-blue;

        &:before {
            content: "";
            width: rem(8);
            height: rem(32);
            object-fit: contain;
            border-top-right-radius: rem(16);
            border-bottom-right-radius: rem(16);
            box-shadow: 0 0 rem(10) 0 #f49924;
            background-image: linear-gradient(to bottom, #ef4a23, #ef4420);
            display: inline-block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    [class^="icon-"] {
        min-height: rem(55);
        min-width: rem(55);
        font-size: rem(22);
        // width: rem(38);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dusk-blue;
    }

    a {
        color: $dusk-blue;
        font-weight: $font-weight-medium;

        &:hover {
            color: $dusk-blue;
        }
    }
}

.ant-layout-sider-trigger {
    border-top: solid 1px $casper-9;
    text-align: left !important;
    padding-left: rem(20);

    .icon-expand-collapse {
        font-size: rem(16);
        color: $color-black;
    }
}

.ant-layout-sider-collapsed {
    flex: 0 0 rem(55) !important;
    max-width: rem(55) !important;
    min-width: rem(55) !important;
    width: rem(55) !important;

    .ant-layout-sider-trigger {
        width: rem(55) !important;
        padding-left: 0;

        .icon-expand-collapse {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(180deg);
            line-height: 1;
        }
    }
}