// platform-selection style
@import '../../../../assets/sass/variables';

.home-page-row {
  min-height: calc(100vh - rem(55));
  align-items: center;
}

.home-page-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  p {
    font-size: rem(18);
    color: $theme-gray;
  }
}