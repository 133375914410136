// ant-button style

// :where(.css-dev-only-do-not-override-1jr9qlj).ant-btn-primary {
//     background-color: $color-theme-orange;
// }

// :where(.css-dev-only-do-not-override-1jr9qlj).ant-btn.ant-btn-lg {
//     border-radius: rem(4);
// }

.btn-white {
    background-color: $color-white !important;
    font-weight: $font-weight-regular;
}

.grayBg-btn {
    background-color: $light-blue;
    border-color: $light-blue;
    color: $color-primary;
    font-weight: $font-weight-regular;

    &:hover,
    &:focus,
    &:active {
        border-color: $light-blue !important;
        color: $color-primary !important;
    }
}

.ant-btn {
    font-weight: $font-weight-bold;

    &.ant-btn-lg {
        font-size: rem(16);
        padding-right: rem(32);
        padding-left: rem(32);
    }

    @media only screen and (max-width: 575.98px) {
        width: 100%;
    }

    @media only screen and (max-width: 479.98px) {
        min-height: rem(48);
        height: auto !important;
        width: 100%;
        white-space: normal;
        padding-right: rem(6) !important;
        padding-left: rem(6) !important;
    }
}

.ant-btn-default {
    background-color: $light-blue;
    border-color: $border-color;
    color: $dark-gray;

    &:not(:disabled):hover,
    &:not(:disabled):active {
        color: $color-theme-orange !important;
        border-color: $color-theme-orange !important;
    }
}

.ant-btn-primary {

    &:not(:disabled):hover,
    &:not(:disabled):active {
        background-color: $color-theme-orange !important;
    }
}

.ant-btn-secondary {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;

    &:not(:disabled):hover,
    &:not(:disabled):active {
        color: $color-white;
        border-color: $color-primary;
    }

    &:disabled {
        cursor: not-allowed;
        border-color: rgba(46, 49, 146, 0.1);
        background-color: rgba(46, 49, 146, 0.4);
    }
}

.btn-sort.ant-btn-default {
    background-color: $color-white !important;
    font-size: rem(14);
    font-weight: $font-weight-regular;
    padding: 0 rem(16);
    min-height: rem(34);
    cursor: pointer;

    display: flex;
    align-items: center;

    &>span {
        margin-right: rem(10);
    }

    &:hover,
    &.active,
    &:not(:disabled):hover,
    &:not(:disabled):active {
        border-color: $color-primary !important;
        background-color: $color-primary !important;
        color: $color-white !important;
    }

    &:hover {
        .anticon svg path {
            fill: $color-white;
        }
    }

    i {
        line-height: 0;
    }

    .anticon {
        margin: 0 !important;
    }

    .desc .anticon {
        rotate: 180deg;
    }
}