// App Fonts

// @mixin custom-font-face($name, $file, $category:"", $path: "") {
//     $filepath: $path + "/"+ $file;

//     @font-face {
//         font-family: "#{$name}";
//         src: url($filepath + ".eot");
//         src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
//         url($filepath + ".woff2") format('woff2'),
//         url($filepath + ".woff") format('woff'),
//         url($filepath + ".ttf") format('truetype'),
//         url($filepath + ".svg#" + $name + "#{$name}") format('svg');
//         font-style: normal;
//         font-weight: normal;
//         speak: none;
//         font-variant: normal;
//         text-transform: none;
//     }
// }

// @include custom-font-face("DIN2014", "Brandon-Grotesque-Bold", "", "../../assets/fonts/DIN2014");
// @include custom-font-face("DIN2014", "DIN2014-Medium", "", "../../assets/fonts/DIN2014");
// @include custom-font-face("DIN2014", "DIN2014-Bold", "", "../../assets/fonts/DIN2014");


@font-face {
    font-family: 'DIN2014';
    src: url('../fonts/DIN2014/DIN2014-Regular.eot');
    src: url('../fonts/DIN2014/DIN2014-Regular.eot#iefix') format('embedded-opentype'),
        url('../fonts/DIN2014/DIN2014-Regular.ttf') format('truetype'),
        url('../fonts/DIN2014/DIN2014-Regular.woff') format('woff'),
        url('../fonts/DIN2014/DIN2014-Regular.svg#DIN2014-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'DIN2014';
    src: url('../fonts/DIN2014/DIN2014-Medium.eot');
    src: url('../fonts/DIN2014/DIN2014-Medium.eot#iefix') format('embedded-opentype'),
        url('../fonts/DIN2014/DIN2014-Medium.ttf') format('truetype'),
        url('../fonts/DIN2014/DIN2014-Medium.woff') format('woff'),
        url('../fonts/DIN2014/DIN2014-Medium.svg#DIN2014-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'DIN2014';
    src: url('../fonts/DIN2014/DIN2014-Bold.eot');
    src: url('../fonts/DIN2014/DIN2014-Bold.eot#iefix') format('embedded-opentype'),
        url('../fonts/DIN2014/DIN2014-Bold.ttf') format('truetype'),
        url('../fonts/DIN2014/DIN2014-Bold.woff') format('woff'),
        url('../fonts/DIN2014/DIN2014-Bold.svg#DIN2014-Bold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Brandon-Grotesque';
    src: url('../fonts/Brandon-Grotesque/Brandon-Grotesque-Regular.eot');
    src: url('../fonts/Brandon-Grotesque/Brandon-Grotesque-Regular.eot#iefix') format('embedded-opentype'),
        url('../fonts/Brandon-Grotesque/Brandon-Grotesque-Regular.ttf') format('truetype'),
        url('../fonts/Brandon-Grotesque/Brandon-Grotesque-Regular.woff') format('woff'),
        url('../fonts/Brandon-Grotesque/Brandon-Grotesque-Regular.svg#Brandon-Grotesque-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Brandon-Grotesque';
    src: url('../fonts/Brandon-Grotesque/Brandon-Grotesque-Bold.eot');
    src: url('../fonts/Brandon-Grotesque/Brandon-Grotesque-Bold.eot#iefix') format('embedded-opentype'),
        url('../fonts/Brandon-Grotesque/Brandon-Grotesque-Bold.ttf') format('truetype'),
        url('../fonts/Brandon-Grotesque/Brandon-Grotesque-Bold.woff') format('woff'),
        url('../fonts/Brandon-Grotesque/Brandon-Grotesque-Bold.svg#Brandon-Grotesque-Bold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/icomoon.eot?qneosx');
    src: url('../fonts/icomoon/icomoon.eot?qneosx#ie') format('embedded-opentype'),
        url('../fonts/icomoon/icomoon.ttf?qneosx') format('truetype'),
        url('../fonts/icomoon/icomoon.woff?qneosx') format('woff'),
        url('../fonts/icomoon/icomoon.svg?qneosx#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-action-items:before {
    content: "\e907";
}

.icon-companies:before {
    content: "\e902";
}

.icon-dashboard:before {
    content: "\e90a";
}

.icon-data-export:before {
    content: "\e901";
}

.icon-expand-collapse:before {
    content: "\e900";
}

.icon-feed-intake .path1:before {
    content: "\e90b";
    color: rgb(95, 99, 106);
}

.icon-feed-intake .path2:before {
    content: "\e90c";
    margin-left: -1.02734375em;
    color: rgb(95, 99, 106);
}

.icon-feed-intake .path3:before {
    content: "\e90d";
    margin-left: -1.02734375em;
    color: rgb(95, 99, 106);
}

.icon-feed-intake .path4:before {
    content: "\e90e";
    margin-left: -1.02734375em;
    color: rgb(95, 99, 106);
}

.icon-feed-intake .path5:before {
    content: "\e90f";
    margin-left: -1.02734375em;
    color: rgb(241, 243, 249);
}

.icon-feed-intake .path6:before {
    content: "\e910";
    margin-left: -1.02734375em;
    color: rgb(95, 99, 106);
}

.icon-feed-intake .path7:before {
    content: "\e911";
    margin-left: -1.02734375em;
    color: rgb(95, 99, 106);
}

.icon-feed-intake .path8:before {
    content: "\e912";
    margin-left: -1.02734375em;
    color: rgb(95, 99, 106);
}

.icon-in-pen-weight .path1:before {
    content: "\e913";
    color: rgb(95, 99, 106);
}

.icon-in-pen-weight .path2:before {
    content: "\e916";
    margin-left: -1.02734375em;
    color: rgb(241, 243, 249);
}

.icon-in-pen-weight .path3:before {
    content: "\e917";
    margin-left: -1.02734375em;
    color: rgb(95, 99, 106);
}

.icon-in-pen-weight .path4:before {
    content: "\e918";
    margin-left: -1.02734375em;
    color: rgb(95, 99, 106);
}

.icon-in-pen-weight .path5:before {
    content: "\e919";
    margin-left: -1.02734375em;
    color: rgb(95, 99, 106);
}

.icon-ipw-and-spray .path1:before {
    content: "\e91a";
    color: rgb(95, 99, 106);
}

.icon-ipw-and-spray .path2:before {
    content: "\e91f";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.icon-ipw-and-water-intake .path1:before {
    content: "\e91b";
    color: rgb(241, 243, 249);
}

.icon-ipw-and-water-intake .path2:before {
    content: "\e91c";
    margin-left: -1em;
    color: rgb(95, 99, 106);
}

.icon-ipw-and-water-intake .path3:before {
    content: "\e91d";
    margin-left: -1em;
    color: rgb(95, 99, 106);
}

.icon-ipw-and-water-intake .path4:before {
    content: "\e91e";
    margin-left: -1em;
    color: rgb(95, 99, 106);
}

.icon-live-status:before {
    content: "\e908";
}

.icon-location:before {
    content: "\e903";
}

.icon-pen-check:before {
    content: "\e920";
}

.icon-plug-circle-bolt:before {
    content: "\e914";
}

.icon-solar-panel:before {
    content: "\e915";
}

.icon-toggle:before {
    content: "\e909";
}

.icon-tools:before {
    content: "\e905";
}

.icon-trials:before {
    content: "\e906";
}

.icon-user:before {
    content: "\e904";
}