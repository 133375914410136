// label-value style
@import '../../assets/sass/variables';

.file-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.file-not-found-message {
    font-family: $font-primary;
    font-size: rem(18);
    color: $theme-gray;
}