/*
    Notifications Helper
*/
@import '../assets/sass/variables';

// :where(.css-dev-only-do-not-override-1jr9qlj)[class^="ant-notification"],
// :where(.css-dev-only-do-not-override-1jr9qlj)[class*=" ant-notification"] {
//     font-family: $font-primary;

//     .ant-notification-notice-close {
//         top: rem(27) !important;
//     }
// }

.notification-success {
    .ant-notification-notice-icon svg path {
        fill: #00f823;
    }
}

.notification-error {
    .ant-notification-notice-icon svg path {
        fill: #FF4D4F;
    }
}

.notification-warning {
    .ant-notification-notice-icon svg path {
        fill: #FAAD14;
    }
}

.notification-info {
    .ant-notification-notice-icon svg path {
        fill: #1890FF;
    }
}

.ant-notification-notice-message {
    margin-bottom: 0 !important;
}

.ant-notification-notice-description {
    padding-inline-end: rem(24);

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
}