// ant-header style

.app-header {
    background: $color-white;
    border-bottom: 1px solid $color-silver;
    width: 100%;
    padding: 0 rem(20);
    min-height: rem(53);
    line-height: rem(53);
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>div {
        width: 100%;
    }
}

.frameIcon {
    display: flex;
    align-items: center;
    padding-left: rem(18);
    margin-right: rem(16);
    margin-left: rem(-20);
    cursor: pointer;

    [class^="icon-"] {
        vertical-align: middle;
        font-size: rem(20);
        color: rgba(38, 38, 38, 0.45);
    }

    &:after {
        content: '';
        width: 1px;
        min-height: rem(53);
        background-color: #dadada;
        margin-left: rem(18);
    }
}


.userProfile {
    .userProfile-btn {
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        line-height: 1;

        .user-title-circle {
            text-align: center;
            background: $dusk-blue;
            min-width: rem(24);
            min-height: rem(24);
            border-radius: 100%;
            cursor: pointer;
            margin-right: rem(8);
            display: flex;
            justify-content: center;
            align-items: center;

            span,
            a {
                text-transform: capitalize;
                font-size: rem(10);
                color: $color-white;
            }
        }

        .user-title-full {
            font-size: rem(14);
            margin-right: rem(10);

            @media (max-width: 479.98px) {
                display: none;
            }
        }

        .arrow {
            svg path {
                fill: $color-black;
            }
        }
    }
}

.userProfile-menu {
    min-width: rem(260);

    &.ant-dropdown-menu {
        padding-right: 0;
        padding-left: 0;
        border-radius: 0;

        .ant-dropdown-menu-title-content {
            color: $dusk-blue;
            font-size: rem(17);
            padding: rem(6) 0;
        }
    }
}

.ant-dropdown.ant-dropdown-placement-bottomRight {
    top: rem(54) !important;
    right: rem(5) !important;
    left: auto !important;
    z-index: 9999;

    .ant-dropdown-menu {
        padding: 0;
    }

    .ant-dropdown-menu-title-content {
        color: #2e3092;
        font-size: rem(17);
        display: block;
        padding: 0.3125em 0;
    }
}