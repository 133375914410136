// Importing Ant Design CSS
// ANT-Design Doc: https://ant.design/docs/react/getting-started
// @import 'antd/dist/antd.css';

// If you want to override variables do it here
@import "variables";

// If you want to override mixins do it here
@import "form";

// If you want to add custom font do it here
@import "fonts";

// Ant Overrides
@import "./framework/_ant-aside";
@import "./framework/_ant-button";
@import "./framework/_ant-card";
@import "./framework/_ant-header";
@import "./framework/_ant-tabs";
@import "./framework/_ant-modal";
@import "./framework/_ant-table";
@import "./framework/_ant-form";

// If you want to add something do it here
@import "custom";

// If you want to work on Responsiveness
@import "responsive";

// Antdesign utility classes
@import "antd-css-utilities/utility.min.css";
