// ant-tabs style

.ant-tabs {
    .ant-tabs-tab {
        margin-left: rem(0) !important;

        &:first-child {
            margin-left: rem(24);
        }
    }

    // .ant-tabs-tab,
    .ant-tabs-tab+.ant-tabs-tab {
        margin-left: rem(0);
    }

    .ant-tabs-tab {
        min-width: rem(105);
        padding: rem(8) rem(16);
        line-height: rem(22);
        color: $dark-gray;
        justify-content: center;
        // padding: rem(8) rem(34);
    }

    .ant-tabs-tab:hover,
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $color-primary;
    }

    .ant-tabs-ink-bar {
        background: $color-primary;
    }
}

.ant-tabs-top>.ant-tabs-nav::before {
    border-bottom-color: $border-color;
}

@media (max-width: 767.98px) {
    .ant-tabs-nav {
        flex-direction: column;

        .ant-tabs-extra-content {
            margin-top: rem(20);
            width: 100%;
        }
    }
}

@media (min-width: 599.98px) {
    .ant-tabs-nav-list {
        transform: none !important;
    }
}