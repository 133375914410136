// App form common UI

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-item-detail {
    font-size: rem(14);
    color: $medium-gray;
    margin-top: rem(-20);
    margin-bottom: 0;
    font-weight: $font-weight-bold;
}

.action-btn {
    font-size: rem(14);
    color: #ED413D;

    .anticon {
        margin-right: rem(10);
    }
}

.form-steps {
    color: $theme-gray;

    b {
        font-weight: $font-weight-bold;
        color: $dark-gray;
    }
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    background-color: $color-primary;
    border-color: $color-primary;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: $color-primary;
}

@media (max-width: 575.98px) {
    .form-margin-control {
        .ant-form-item {
            margin-bottom: rem(12);
        }
    }
}

@media (max-width: 576px) {
    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
        flex-direction: column;
        max-height: 80vh;
        overflow: auto;
    }
}