// ant-modal style

.ant-modal {
    top: rem(53);
    height: auto !important;
    padding: 0;

    @media (min-height: 300px) and (max-height: 699.98px) {
        top: 50%;
        transform: translateY(-50%);
    }

    @media (max-height: 300px) {
        top: 0;
        padding-top: rem(30);
        padding-bottom: rem(30);
    }

    .ant-modal-content {
        box-shadow: none;
        outline: none;
        border-radius: rem(4);
        // padding: rem(24) 0;
        padding: 0;
        overflow: hidden;
    }

    .ant-modal-title {
        font-family: $font-secondary;
        color: $color-primary;
        font-size: rem(24);
        padding-right: rem(20);
        padding-bottom: rem(20);
    }

    .ant-modal-close {
        top: rem(30);
    }

    .ant-modal-body {
        padding: rem(24) !important;
        // max-height: calc(100vh - rem(140));
        max-height: calc(100vh - (rem(53) + rem(55) + rem(25) + rem(53)));
        min-height: rem(193);
        overflow: auto;

        .ant-btn {
            font-family: $font-primary;
            border-radius: rem(4);

            &-default {
                background-color: $light-blue;
                border-color: $border-color;
                color: $dark-gray;
            }

            &-primary {
                background-color: $color-theme-orange;
            }
        }
    }
}

.ant-modal-confirm-title {
    font-family: $font-secondary !important;
    font-size: rem(24) !important;
    color: $color-theme-orange !important;
    padding-right: rem(24);
}

.ant-modal-confirm-content {
    font-family: $font-primary !important;
    font-size: rem(16) !important;
    color: $theme-gray !important;
}

.ant-modal.filter-modal {
    @media (min-width: 767.98px) {
        top: rem(60);
        margin-right: rem(60);
        transform: translateY(0px);
    }
}

.ant-modal.filter-modal,
.ant-modal.form-modal,
.ant-modal.notification-modal {
    .ant-modal-header {
        padding: rem(24) rem(24) 0 rem(24);
        margin: 0;
    }
}

.ant-modal.filter-modal,
.ant-modal.form-modal {
    .ant-modal-header {
        border-bottom: solid 1px $border-color;
        // margin: 0 0 rem(32) 0;
    }
}

.ant-modal.notification-modal {
    // padding: 0 rem(24) 0 rem(24);
    // margin: 0 0 rem(32) 0;
}

.ant-modal-confirm-btns {
    margin-top: rem(30) !important;

    button {
        @media (min-width: 480px) and (max-width: 575.98px) {
            margin-right: rem(12);
            margin-left: rem(12);
            width: calc(50% - rem(12));

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        @media only screen and (max-width: 479.98px) {
            margin: 0 0 rem(13) !important;
            min-height: rem(48);
            height: auto;
            white-space: normal;
            width: 100%;
            padding-right: rem(6);
            padding-left: rem(6);

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}