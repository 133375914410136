// ant-table style
.table-container,
.ant-table-container {
    table {
        table-layout: auto !important;

        th,
        td {
            padding-top: rem(5) !important;
            padding-bottom: rem(5) !important;
        }
    }
}

.table-container {
    border: 1px solid $border-color;
    border-radius: rem(4) rem(4) 0 0;
    min-height: rem(300) !important;
    // height: calc(100vh - (rem(130) + rem(20) + rem(56) + rem(16) + rem(16) + rem(72) + rem(32) + rem(53))) !important;
    // height: calc(100vh - (rem(130) + rem(20) + rem(56) + rem(16) + rem(16) + rem(72) + rem(32))) !important;
    // height: calc(100vh - (rem(130) + rem(20) + rem(56) + rem(16) + rem(16) + rem(72) + rem(16))) !important;
    height: calc(100vh - (rem(56) + rem(20))) !important;
    // overflow: visible !important;
    // height: auto !important;

    &>div:nth-child(1) {
        margin: 0 !important;
        overflow: unset !important;
        position: relative !important;
    }

    &>div:nth-child(2) {
        height: rem(10) !important;
        border-radius: rem(10) !important;

        &>div {
            background-color: $color-primary !important;
        }
    }

    &>div:nth-child(3) {
        top: rem(36) !important;
        width: rem(10) !important;
        border-radius: rem(10) !important;

        &>div {
            background-color: $color-primary !important;
        }
    }
}

.ant-table-wrapper {
    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        content: none;
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
        border-bottom-color: $border-color;
    }

    .ant-table-thead>tr>th {
        position: sticky;
        top: 0px;
        color: #262626;
        z-index: 9;

        .anticon {
            svg path {
                fill: $theme-gray;
            }

            &.active {
                svg path {
                    fill: #999999;
                }
            }
        }
    }

    .ant-table-column-sorters {
        justify-content: flex-start;
    }

    .ant-table-column-sorter {
        margin-inline-start: rem(10);
    }

    .ant-table-body {
        // height: calc(100vh - rem(10));
        min-height: calc(100vh - (rem(130) + rem(20) + rem(56) + rem(16) + rem(16) + rem(72) + rem(32) + rem(53) + rem(60) + rem(10))) !important;
        // max-height: calc(100vh - (rem(130) + rem(20) + rem(56) + rem(16) + rem(16) + rem(72) + rem(32) + rem(53) + rem(60))) !important;
    }
}