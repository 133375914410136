// Authentication style
@import '../../assets/sass/variables';

.authentication-wrapper {
  .image-col {
    background: url(../../assets/images/login-image.jpg) center no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;

    .content {
      padding: 0 rem(65);
      position: absolute;
      bottom: rem(80);
    }

    h1 {
      color: $color-white;
    }

    h2 {
      font-size: rem(32);
      color: $color-white;
      margin-top: 0;
      margin-bottom: rem(20);
      font-weight: $font-weight-regular;
    }

    .btn {
      border: 1px solid $color-white;
      background: transparent;
      font-size: rem(20);
      color: $color-white;
      padding: 15px rem(24);
      display: inline-block;
      text-align: center;
      line-height: normal;
      border-radius: 4px;
      transition: all .3s ease;
      position: relative;
      transition: all .4s ease;
      overflow: hidden;

      &:hover {
        background: hsla(0, 0%, 100%, .3);
      }
    }
  }

  .form-col {
    background-color: $color-white;
    background-image: url(../../assets/images/login-form-bg.svg);
    background-repeat: no-repeat;
    background-position: top right;
    // background-size: 50%;
    display: flex;
    position: relative;
    overflow-y: auto;
    flex-direction: column;
    justify-content: center;
    background-size: rem(300);


    // align-items: stretch;
    // flex-wrap: wrap;
    // justify-content: center;
    // flex-direction: column;

    @media (min-width: 768px) {
      height: 100vh;
      padding: rem(112) rem(60) rem(50);
    }

    @media (max-width: 767.98px) {
      min-height: 100vh;
      overflow-y: auto;
      align-content: center;
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
      padding: rem(112) rem(60) rem(50);
    }

    @media (min-width: 480px) and (max-width: 575.98px) {
      padding: rem(50) rem(30);
    }

    @media (max-width: 479.98px) {
      padding: rem(30) rem(16);
    }

    &.signup-page {
      background-position: right top rem(50);
    }

    &.cye-box {
      background-position: right top rem(47);
    }

    input {
      background: transparent;

      &.ant-input-status-error {
        border-color: $color-danger !important;
      }
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
      &:hover {
        border-color: transparent;
        box-shadow: 0 0 0 2px rgba(217, 217, 217, 0.6);

        .ant-input {
          box-shadow: none;
        }

        &.ant-input-affix-wrapper-status-error {
          border-color: $color-danger;
        }
      }
    }

    input[type="text"],
    input[type="password"] {
      height: auto !important;
    }

    .ant-input-password {
      min-height: rem(48);

      input {
        min-height: auto;
      }
    }

    .login-logo {
      min-width: rem(170);
      max-width: rem(170);

      @media (min-width: 768px) {
        margin: 0 0 rem(60);
      }

      @media (max-width: 767.98px) {
        margin: 0 0 rem(40);
      }
    }

    .shadow-card {
      background-color: $color-white;
      box-shadow: 0 0 rem(30) 0 rgba(0, 0, 0, 0.1);
      border-radius: rem(4);

      @media (min-width: 768px) {
        padding: rem(25) rem(30) rem(6);
        margin-bottom: rem(56);
      }

      @media (max-width: 767.98px) {
        padding: rem(24) rem(24) rem(6);
        margin-bottom: rem(40);
      }

      a {
        font-size: rem(14);
        text-decoration: underline;
        margin: rem(-10) 0 0;
        color: #748393;

        &:hover {
          color: $color-orange;
        }
      }
    }

    .form-control {
      font-size: rem(16);
      margin-bottom: 0;
      background-color: transparent;
    }

    .sub-head {
      font-family: $font-secondary;
      color: $color-primary;
      text-transform: uppercase;
      margin: 0 0 rem(20);
      font-size: rem(25);
      letter-spacing: 1px;
    }

    .auth-action {
      padding: rem(11.5) rem(0) !important;
      height: auto;
    }

    .input-group {
      .error {
        margin: rem(3) 0 0;
        display: block;
      }
    }

    p {
      font-size: rem(16);
      color: $dark-gray;
    }
  }
}

.back-link {
  margin: 0 auto rem(30);

  a {
    display: flex;
    font-size: rem(18);
    color: $dark-gray;
    font-weight: $font-weight-medium;

    .anticon svg {
      width: rem(24);
      height: rem(24);
      margin-right: rem(10);

      path {
        fill: $dark-gray;
      }
    }
  }
}

.footer-links {
  font-size: rem(17);
  margin: 0 auto;
  text-align: center;

  span {
    color: #262626;
    margin-right: rem(16);
  }

  a {
    color: $color-primary;
  }
}