// ant-card style

// .generic-card-list {
    .ant-card:hover {
        background-color: $light-blue-hover;
        cursor: pointer;
    }
// }

.ant-card {
    // border-radius: rem(4);

    &-bordered {
        border-color: $border-color;
    }

    .ant-checkbox-wrapper {
        font-size: rem(26);

        .ant-checkbox {
            font-size: rem(26);

            .ant-checkbox-inner {
                width: rem(26);
                height: rem(26);
                border-radius: 50%;
            }
        }
    }

    .ant-checkbox-checked:after {
        border-radius: 50%;
    }
    
    .ant-checkbox-inner:after{
        inset-inline-start: 30%;
    }

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
    .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        border-color: $color-orange;
        background-color: $color-orange;
    }

    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
        border-color: $color-orange;
    }
}

.ant-card .ant-card-body {
    // padding: rem(24) rem(20) rem(20) rem(20);
    padding: rem(16);
}

.card-title {
    margin: 0;
    color: $color-primary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


h3.card-title {
    font-size: rem(20);

    .ant-tooltip-open {
        font-size: rem(20);
    }
}

h4.card-title {
    font-size: rem(14);

    .ant-tooltip-open {
        font-size: rem(14);
    }
}

.card-status {
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    // text-align: right;
    font-size: rem(12);
    white-space: nowrap;
}