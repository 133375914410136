// platform-selection style
@import '../../../assets/sass/variables';

.platform-selection {
  background-image: url('../../../assets/images/platform-selection-bg.svg');
  background-position: right rem(-53);
  background-repeat: no-repeat;
  height: calc(100vh - (rem(53)));
  // height: auto;
  overflow-y: auto;

  .platform-layout {
    max-width: rem(992);
    margin: 0 auto;
    padding: rem(32) rem(16);

    @media (min-width: 576px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-height: 699.98px) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      &>div {
        padding-bottom: rem(32);
      }
    }

    @media (max-width: 699.98px) {
      &>div {
        padding-bottom: rem(32);
      }
    }
  }
}

.user-info {
  color: $dark-gray;

  h3 {
    font-size: rem(20);
    font-weight: $font-weight-bold;
    margin: 0;
  }

  p {
    font-size: rem(16);
    margin-top: rem(8);
  }
}

.platforms {
  &-item>div {
    height: 100%;

    &:hover {
      background-color: $color-white;
      box-shadow: 0 0 rem(30) 0 rgba(0, 0, 0, .1);
      border-color: transparent;
      cursor: pointer;
    }

    p {
      font-size: rem(16);
      margin-top: rem(8);
      margin-bottom: 0;
      position: relative;
      color: $theme-gray;

      @media (min-width: 576px) {
        padding-right: rem(70);
      }

      @media (max-width: 575.98px) {
        padding-right: rem(24);
      }

      .anticon {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}