// Custom stylesheet
html,
header,
body,
footer {
    box-sizing: border-box;
}

// ======= Global Media =======
// @media screen and (min-width: 5800px) {
//     html {
//         font-size: 66px;
//     }
// }

// @media (min-width: 3840px) and (max-width: 5799.98px) {
//     html {
//         font-size: 52px;
//     }
// }

// @media (min-width: 3120px) and (max-width: 3839.98px) {
//     html {
//         font-size: 33.5px;
//     }
// }

// @media (min-width: 2880px) and (max-width: 3119.98px) {
//     html {
//         font-size: 25.5px;
//     }
// }

// @media (min-width: 2560px) and (max-width: 2879.98px) {
//     html {
//         font-size: 23px;
//     }
// }

// @media (min-width: 2400px) and (max-width: 2559.98px) {
//     html {
//         font-size: 21.5px;
//     }
// }

// @media (min-width: 2100px) and (max-width: 2399.98px) {
//     html {
//         font-size: 20px;
//     }
// }

// @media (min-width: 1920px) and (max-width: 2099.98px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media (min-width: 1900px) and (max-width: 1919.98px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media (min-width: 1800px) and (max-width: 1899.98px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media (min-width: 1600px) and (max-width: 1799.98px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media (min-width: 1400px) and (max-width: 1599.98px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media (min-width: 1200px) and (max-width: 1399.98px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media (min-width: 992px) and (max-width: 1199.98px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media (min-width: 768px) and (max-width: 991.98px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media (min-width: 576px) and (max-width: 767.98px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media (min-width: 480px) and (max-width: 575.98px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media (min-width: 320px) and (max-width: 479.98px) {
//     html {
//         font-size: 15px;
//     }
// }

// @media screen and (max-width: 319.98px) {
//     html {
//         font-size: 14px;
//     }
// }

// ::-webkit-scrollbar {
//     width: rem(5);
//     height: rem(5);
// }

// ::-webkit-scrollbar-track {
//     background: $color-white;
// }

// ::-webkit-scrollbar-thumb {
//     background: $color-primary;
//     border-radius: rem(4);
// }

// ::-webkit-scrollbar-thumb:hover {
//     background: $color-primary;
// }

.themescrollbar::-webkit-scrollbar {
    width: rem(10);
    height: rem(10);
}

.themescrollbar::-webkit-scrollbar-track {
    background: $color-white;
}

.themescrollbar::-webkit-scrollbar-thumb {
    background: $color-primary;
    border-radius: rem(4);
}

.themescrollbar::-webkit-scrollbar-thumb:hover {
    background: $color-primary;
}

body {
    margin: 0;
    padding: 0;
    font-size: rem(16);
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $font-secondary;
}

// common style
// .bt-0 {
//     border-top: 0 !important;
// }

// .bb-0 {
//     border-bottom: 0 !important;
// }

.bt-1 {
    border-top: 1px solid $border-color;
}

.bb-1 {
    border-bottom: 1px solid $border-color;
}

.col-5 {

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
        width: 20%;
    }

    // Large devices (tablets, 768px and up)
    @media (min-width: 768px) and (max-width: 1199.98px) {
        width: 33.3333%;
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 50%;
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
        width: 100%;
    }
}

.ant-tooltip-inner .item-count {
    display: none;
}

// .ant-badge override
.ant-badge.ant-badge-status {
    // display: inline-flex;
    // align-items: center;
    // line-height: 1;

    .ant-badge-status-dot {
        top: rem(-2);
    }

    .ant-badge-status-text {
        font-size: rem(12);
    }
}


.color-gray {
    color: $dark-gray-light;
}


// App Loader
.app-loader {
    .ant-spin {
        position: fixed;
        z-index: 1052;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;

        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-spin-dot {
        font-size: rem(24);
        width: rem(34);
        height: rem(34);

        .ant-spin-dot-item {
            background-color: $color-orange;
            width: rem(14);
            height: rem(14);
        }
    }
}

.ant-spin-nested-loading {
    >div {
        >.ant-spin.app-loader {
            .ant-spin-dot {
                position: fixed;
            }
        }
    }
}



// ant-layout override
.ant-layout,
.ant-layout-footer {
    background-color: $color-white;
}

.page-layout {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - rem(54));
    // height: calc(100vh - rem(54));

    &-header {
        &>.ant-row {
            min-height: rem(48);
        }

        .main-head {
            font-family: $font-secondary;
            color: $color-primary;
            font-size: rem(28);

            sup {
                font-size: rem(12);
                color: $theme-gray;
            }

            &-secondary-items {
                margin-left: rem(10);
            }
        }

        .title-cont {
            display: flex;
            align-items: center;

            @media only screen and (max-width: 479.98px) {
                width: 100%;
            }
        }

        .main-head-action {
            // display: flex;
            gap: rem(13);

            @media only screen and (min-width: 576px) {
                justify-content: flex-end;
                margin-left: auto;
            }

            @media only screen and (min-width: 480px) {
                flex-wrap: wrap;
                align-items: center;
                // row-gap: rem(13);
            }

            @media only screen and (max-width: 479.98px) {
                flex-direction: column;
                width: 100%;
                margin-top: rem(10);
            }

            @media (min-width: 480px) and (max-width: 575.98px) {
                width: 100%;
            }

            p {
                margin: 0;
                color: $dark-gray-light;

                a {
                    font-weight: $font-weight-medium;
                    text-decoration: underline;
                    color: $color-primary;
                    margin-left: rem(5);
                }
            }

            button {
                // @media only screen and (min-width: 480px) {
                //     margin-left: rem(13);
                // }

                @media (min-width: 480px) and (max-width: 575.98px) {
                    // min-width: calc(50% - rem(7));
                    // width: auto;
                    width: calc(50% - rem(7));

                    &:nth-child(odd) {
                        margin: 0;
                    }

                    &:only-child {
                        width: 100%;
                    }
                }

                @media only screen and (max-width: 479.98px) {
                    // margin-top: rem(13);
                    min-height: rem(48);
                    height: auto;
                    white-space: normal;
                    width: 100%;
                    padding-right: rem(6);
                    padding-left: rem(6);
                }
            }
        }
    }

    &-body {
        &.h100 {
            height: 100%;
        }
    }

    &-footer {
        &.list-item-desc .ant-col {
            @media (min-width: 480px) and (max-width: 1199.98px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        @media (max-width: 1199.98px) {
            padding-top: rem(24);
        }
    }
}

//list-item styling
.listing-tab-layout {
    .page-layout-body {
        border-top: 0;
        padding-top: 0 !important;
    }

    .ant-tabs-nav {
        margin-right: rem(-24);
        margin-left: rem(-24);
    }

    .search-filter-sec {
        @media (max-width: 767.98px) {
            margin-top: rem(0);
            margin-bottom: rem(16);
            padding-right: rem(24);
            padding-left: rem(24);
        }
    }
}

.list-item-title .ant-col {
    display: flex;
    width: 100%;

    // .card-title {
    //     padding-right: rem(16);
    // }

    .card-status {
        padding-left: rem(16);

        .ant-badge.ant-badge-status {
            line-height: 6px;
        }
    }
}

.list-item-detail {
    // border-bottom: 1px solid $light-blue;

    .item {
        font-size: rem(15);
        padding-bottom: rem(2);
        color: $theme-gray;

        .label {
            margin-right: rem(5);
            font-weight: $font-weight-medium;
        }

        .value {
            word-wrap: break-word;
        }
    }

    .detail-icon {
        display: flex;
        justify-content: flex-end;

        [class^="icon-"] {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $light-blue;
            width: rem(28);
            height: rem(28);
            color: $color-primary;
            border-radius: 50%;

            &:before {
                font-size: rem(14);
            }
        }
    }
}

.list-item-icon-detail {
    &.row-view {
        padding-top: rem(8);
        // border-top: 1px solid $border-color;

        .ant-col>span {
            display: flex;
            align-items: center;
        }

        .icon {
            min-width: rem(20);

            svg {
                // width: rem(14);
                // max-height: rem(16);
                // min-width: rem(28);
                // max-height: rem(28);
                min-width: rem(22);
                max-height: rem(22);
                width: auto;
                height: auto;

                path {
                    fill: $medium-gray;
                }
            }
        }

        .value {
            margin-left: rem(8);
            font-size: rem(15);
            color: $theme-gray;
        }
    }

    &.column-view {
        .icon {
            display: flex;
            justify-content: center;

            svg {
                width: rem(38);
                height: rem(38);
            }
        }

        .value {
            display: flex;
            justify-content: center;
            margin-top: rem(8);
            font-size: rem(14);
            color: $theme-gray;
        }
    }
}


//search-filter-sec style
.sort-search-wrapper {
    @media (min-width: 768px) {
        flex-wrap: nowrap;
    }
}

.search-filter-sec {
    .search-bar-col {
        @media (min-width: 768px) {
            width: calc(100% - rem(56));
        }

        @media (min-width: 480px) and (max-width: 767.98px) {
            width: calc(100% - rem(52));
        }

        @media (max-width: 479.98px) {
            width: calc(100% - rem(34));
        }
    }

    .filter-col {}

    @media (max-width: 767.98px) {
        margin-top: rem(20);
        width: 100%;
    }
}

.sort-tag-wrap {
    display: flex;
    align-items: center;

    .ant-space {
        gap: rem(8) !important;
    }
}

.filter-badge {
    position: relative;

    .ant-badge {
        position: absolute;
        top: 0;
        right: rem(3);

        .ant-badge-count {
            font-size: rem(12);
            min-width: rem(18);
            height: rem(18);
            line-height: rem(20);
        }

        .ant-scroll-number .ant-scroll-number-only {
            height: rem(18);
        }
    }
}

.grid-search {
    border-bottom: 1px solid $theme-gray;

    .ant-input {
        padding-left: rem(16) !important;
        border: none;
        border-radius: 0;
        min-height: auto;
        background-color: transparent;

        &:hover,
        &:focus {
            box-shadow: none;
        }
    }

    .ant-input-affix-wrapper {
        border: none;

        &:focus,
        &-focused {
            box-shadow: none;
        }
    }

    .ant-input-group-addon {
        position: absolute;
        left: 0;
        top: 0;
        inset-inline-start: 0 !important;
        background-color: transparent;
        z-index: 9;

        .ant-input-search-button {
            background-color: transparent;
            box-shadow: none;
            border: none;
            border-radius: 0 !important;
            height: rem(30);
            padding: 0;

            &:hover {
                background-color: transparent !important;
            }

            @media only screen and (max-width: 479.98px) {
                min-height: auto;
            }
        }
    }
}

// ant progress-bar
.ant-progress-line {
    width: rem(110);

    .ant-progress-inner {
        border: 1px solid $border-color;
    }
}

// Antd List Grid UI update
.ant-list-grid {
    .ant-spin-container>.ant-row>div {
        display: flex;
        flex-direction: column;
    }

    .ant-spin-container>.ant-row>div>.ant-col {
        display: inline-flex;
        flex-direction: column;
        align-self: stretch;
        height: auto;
    }

    .ant-spin-container>.ant-row>div>.ant-col .ant-list-item .ant-card {
        width: 100%;
    }

    .ant-spin-container>.ant-row>div>.ant-col .ant-list-item,
    .ant-spin-container>.ant-row>div>.ant-col .ant-list-item .ant-card {
        height: 100%;
    }
}


.list-item-desc {

    // margin-top: rem(16) !important;

    .ant-col {
        @media (max-width: 1199.98px) {
            margin-bottom: rem(22);

            &:last-child {
                margin-bottom: 0;
            }
        }

        @media (min-width: 768px) and (max-width: 1199.98px) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }

        @media (min-width: 480px) and (max-width: 767.98px) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media (max-width: 479.98px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media (max-width: 1199.98px) {
        .ant-divider {
            margin-top: 0;
        }
    }
}

// .trial-info .ant-col.divider {
.ant-col.divider {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin: 0;
}

// ant-result messages style
.ant-result {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ant-result-icon {
        svg {
            width: 80%;
            height: 80%;
            margin: 0 auto;
        }
    }

    .ant-result-subtitle {
        font-size: rem(18);

        @media only screen and (min-width: 1600px) {
            width: 30%;
        }

        @media (min-width: 1350px) and (max-width: 1599.98px) {
            width: 35%;
        }

        @media (min-width: 1200px) and (max-width: 1349.98px) {
            width: 40%;
        }

        @media (min-width: 992px) and (max-width: 1199.98px) {
            width: 55%;
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            width: 65%;
        }

        @media (min-width: 576px) and (max-width: 767.98px) {
            width: 80%;
        }
    }

    .ant-result-extra {
        .ant-btn {
            font-weight: $font-weight-regular;
        }
    }
}